import * as React from "react"
import Styled from 'styled-components/macro';
import Image from 'gatsby-image';

import Headline from './headline'
import Description from './description'
import Content from './content'
import Container from './container'
import Button from './button'


const ContainerExtended = Styled(Container)`
    padding: 120px 0;
`;

const Text = Styled.div`
  margin-right: 6rem;
`;

const BlogContent = Styled.div`
    align-items: center;
`;

const ContentH5 = Styled.h5`
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 6rem 0 3rem 0;
`;

const ContentH4 = Styled.h4`
  font-size: 3rem;
  line-height: 3.6rem;
  font-weight: 600;
  color: inherit;
  letter-spacing: -0.3px;
  margin: 6rem 0 3rem 0;
`;

const ImgContainer = Styled.div`
    margin: 6rem 0;    
    width: 100%;
    display: flex;
    justify-content: center;
`;

const Img = Styled.img`
    width: 50%;
`;

const NavButtonLink = Styled.a`
  cursor: pointer;
  display: block;
  margin-top: 3rem;
`;

const ExtendedButton = Styled(Button)`
`;

const TitleExtended = Styled(Headline)`
  font-size: 4rem;
  line-height: 5rem;
`;

const Hero = Styled.div`
    max-width: 75%;
    margin: auto;
    text-align: center;
`;

const AngleDown = Styled.span`
    display: inline-block;
    width: 30px;
    height: 30px;
    background: transparent;
    text-indent: -9999px;
    border-top: 2px solid ${(props) => props.theme.colors.black};
    border-left: 2px solid ${(props) => props.theme.colors.black};
    text-decoration: none;
    color: transparent;
    transform: rotate(-135deg);
    margin: 2rem 0;
`;

export default ({colorFlag, headline, description}) => (
    <div>
        <ContainerExtended purple={colorFlag == "purple" ? true : false} green={colorFlag == "green" ? true : false}>
            <Content>
                    <BlogContent>
                        <Hero>
                            <TitleExtended>{headline}</TitleExtended>
                            <AngleDown/>
                        </Hero>
                    </BlogContent>
            </Content>
        </ContainerExtended>
        <ContainerExtended>
            <Content>
                    <BlogContent>
                        <Text>
                            {description.split("<br/>").map((line) => {
                                if (line.indexOf("h5:") != -1) {
                                    return (
                                      <ContentH5>{line.substring(4)}</ContentH5>
                                    )
                                  }
                                if (line.indexOf("h4:") != -1) {
                                    return (
                                      <ContentH4>{line.substring(4)}</ContentH4>
                                    )
                                }
                                if (line.indexOf("unsplash:") != -1) {
                                    return (
                                      <ImgContainer>
                                        <Img src={"https://source.unsplash.com/" + line.substring(10)}/>
                                      </ImgContainer>
                                    )
                                }
                                return (
                                    <Description>{line}</Description>
                                )
                            })}
                        </Text>
                        <NavButtonLink href="/app/">
                            <ExtendedButton green={colorFlag == "green" ? true : false}>
                                Try Design Tool Picker for free
                            </ExtendedButton>
                        </NavButtonLink>
                    </BlogContent>
            </Content>
        </ContainerExtended>
    </div>
)